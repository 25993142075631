@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;600;700;800;900&display=swap');

#root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    font-family: 'poppins';
    color: #263238;
}

main {
    flex: 1 0 auto;
}
/* les directives de tailwindcss */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* mon design */
@layer base {
    button {
        @apply px-4 py-1 rounded-xl
    }
}
.texte {
    @apply font-light mb-3
}
.texte-two {
    @apply font-light mb-2 text-sm
}